<template>
    <div class="container">
        <div v-if="!loadingResult">
            <div class="d-grid d-lg-flex justify-content-lg-end btn-save" >
              <button class="btn btn-primary" type="button" style="text-transform: none" @click="promptPdfActive = true">
                <b-spinner small v-if="enablePDF || isLoading"></b-spinner><i v-else class="feather icon-download"></i><span style="padding-left: 4px;">{{$t('pdf.save_pdf')}}</span> 
              </button>
            </div>
            <b-card no-body class="mt-5">
                <b-tabs content-class="mt-3" card nav-class="md-tabs">
                    <b-tab :title="result.source" v-for="result in computedResults">
                        <photo-source :source="result"></photo-source>
                    </b-tab>
                </b-tabs>
            </b-card>
            <b-modal v-model="promptPdfActive" :title="$t('pdf.save_file')" has-modal-card centered hide-footer trap-focus :destroy-on-hide="false"
                aria-role="dialog" aria-label="Example Modal" aria-modal>
                <PdfPrompt v-bind="formProps" :filename="fileName" @close="closeModal" @pdf-data="pdfUpdateData">
                </PdfPrompt>
            </b-modal>
        </div>
        <div v-else>
            <b-card>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="50%"></b-skeleton>
                <b-skeleton width="75%"></b-skeleton>
                <b-skeleton width="65%"></b-skeleton>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
            </b-card>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import savePdfPhoto from '@/mixins/savePdfPhoto';
    import filters from '@/mixins/filters';
    import _ from 'underscore';
    import photoSource from '../components/photoSearch/photoSource.vue';
    import { faceRepository } from '@/models/face';
    import imageMixin from "@/mixins/imageMixin";
    import imageAxios from "@/configs/iamgeAxios";
    export default {
        name: 'Photo',
        data() {
            return {
                loadingResult: true,
                isLoading: false,
                promptPdfActive: false,
                enablePDF: false,
                portrait: null,
                imageCount: 0,
            }
        },
        mixins: [savePdfPhoto, filters, imageMixin],
        components: {
            photoSource
        },
        computed: {
            ...mapGetters('contacts', ['results', 'meta']),
            computedResults() {
                return this.results.filter(result => result.search_results && result.search_results.length)
            }
        },
        async mounted() {
            await this.getResultById();
            await this.getPortrait();
            await this.loadAllImages();
        },
        methods: {
            async getResultById() {
                return new Promise((resolve) => {
                    if(!_.isUndefined(this.$route.params.id)) {
                        this.loadingResult = true;
                        this.$store.dispatch('contacts/updateResultPush', parseInt(this.$route.params.id)).finally(()=>{
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            resolve();
                            this.loadingResult = false
                        })
                    } else {
                        this.loadingResult = false
                        resolve()
                    }
                })
            },
            async loaderForImages(payload) {
                let result = await this.checkCache(payload);
                if (result.isCached) {
                    this.$store.dispatch('images/addImage', {
                    key: payload,
                    value: ''
                    });
                } else {
                    let axiosSettings;
                    axiosSettings = {
                    method: "get",
                    responseType: 'arraybuffer',
                    headers: {
                        'Authorization': this.token 
                    }
                    }
                    return new Promise((resolve, reject) => {
                    // const imAxios = imageAxios(localStorage.getItem('token'))
                        imageAxios(payload, axiosSettings)
                        .then((resp) => {
                            let blob = new Blob([resp.data], { type: "image/jpg" })
                            let reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = () => {
                                this.saveImage(reader.result, payload)
                                this.$store.dispatch('images/addImage', {
                                key: payload,
                                value: ''
                                });
                            }
                            resolve(resp);
                        })
                        .catch(err => {
                            reject(err)
                        })
                    })
                }
            },
            countImages() {
                for (let result of this.results) {
                    if(result.search_results) {
                        for (let searchResult of result.search_results) {
                            this.imageCount += 1;
                        }
                    }
                }
            },
            async loadAllImages() {
                for (let result of this.results) {
                    if(result.search_results) {
                        for (let searchResult of result.search_results) {
                            await this.loaderForImages(searchResult.image);
                        }
                    }
                }
            },
            closeModal() {
                this.promptPdfActive = false
            },
            getPortrait() {
                faceRepository.getByKey(this.meta.payload.portrait_id)
                .then(response => {
                    this.portrait = response;
                })
            }
        },
    }
</script>