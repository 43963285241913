import {mapGetters} from "vuex";
import chunkArray from "@/helpers/chunk";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "@/helpers/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import PdfPrompt from "@/components/PdfPrompt";
import moment from "moment/moment";
import i18n from "@/i18n";
import _, { includes } from 'underscore';
import {ImageCaching} from "@/mixins/imageClass";
import getImgDimensions from '@/helpers/getImgDimensions.js'

export default {
    data() {
        return {
            resizedImages: 0,
            content: {},
            startedExport: false,
            promptPdfActive: false,
            formProps: {
                filename: null,
                password: null
            },
            fileName: '',
            pdfPassword: 'test',
            needPass: true,
            watermark: true
        }
    },
    components: {
      PdfPrompt
    },
    computed: {
        ...mapGetters('images', ['images', 'allImages','photoLoaded']),
        ...mapGetters('auth', ['user']),

        photosCount() {
            let photoCount = 0;
            for(let result of this.results) {
                for(let sr of result.search_results) {
                    photoCount ++;
                }
            }
            return photoCount
        }

    },
    methods: {
        pdfUpdateData(event) {
            this.fileName = event.filename;
            this.pdfPassword = event.password;
            this.needPass = event.passwordEnable;
            this.watermark = event.watermark;
            this.beforeDownload();
        },



        async  updateContent() {
            let resizedPhotos = 0;
            let content = [];
            let head = true;
            content.push({
                text: this.$t('search.search_by_photo'),
                style: 'header'
            });
            const imageCache = new ImageCaching();
            const imageBase = await imageCache.loadNonComponentImage(this.portrait.result.image_url)
            let mainPhoto =  {
                image: await this.resizeImage(imageBase),
                width: 230
            }
            content.push(mainPhoto);
            for(let level of this.computedResults){
                for(let k in level.search_results) {
                    let stack = {
                        stack: []
                    }
                    
                    let middleArray = stack.stack;
                    let table = {
                        table: {
                            layout: 'lightHorizontalLines',
                            widths: [80, '*'],
                            header: ['header'],
                            body: [],
                            dontBreakRows: true,
                        }
                    };
                    const imageCache = new ImageCaching();
                    let imageBase = await imageCache.loadNonComponentImage(level.search_results[k].image)
                    const dimensions = await getImgDimensions(imageBase);
                    const width = dimensions.w > 230 ? 230 : dimensions.w;
                    let tempImage = await (async () => {
                        return new Promise((resolve) => {
                            if(dimensions.w > 230) {
                                this.resizeImage(imageBase, 230, 230).then((resizedImage) => {
                                    resizedPhotos++;
                                    resolve(resizedImage);
                                })
                            } else {
                                resizedPhotos++;
                                resolve(imageBase);
                            }
                        });
                    } )();
                    middleArray.push({
                        text: level.source,
                        style: 'subheading'
                    })
                    middleArray.push({
                        text: moment(level.search_results[k].date, 'X').format('DD.MM.YYYY HH:mm'),
                    });
                    middleArray.push({
                        image: tempImage,
                        width: width
                    })
                    middleArray.push({link: level.search_results[k].source_url, text: level.search_results[k].source_url});
                    if(level.search_results[k].data.data.length > 0) {
                        for(let indexRow in level.search_results[k].data.data) {
                            let infoRow = level.search_results[k].data.data[indexRow];
                            const fillColors = ['#efefef', '#ffffff'];
                            table.table.body.push(
                                [
                                    { 
                                        fillColor: fillColors[indexRow % 2], 
                                        text: this.$options.filters.capitalize(this.$t('params.' + infoRow.param)), 
                                        bold: true, 
                                        borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'], 
                                    }, 
                                    { fillColor: fillColors[indexRow % 2], text: infoRow.value, borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'], }
                                ])
                        }
                        middleArray.push(table);
                    }
                    
                    let tableToContent = {
                        table: {
                            layout: 'lightHorizontalLines',
                            widths: ['*'],
                            // dontBreakRows: true,
                            // pageBreak: 'before',
                            body: [],
                        }
                    }
                    tableToContent.table.body.push([stack]);
                    content.push({margin: [0, 20], text: ''})
                    content.push(tableToContent)
                }
            }

            this.content = content;
            this.resizedImages = resizedPhotos;
            return content;
        },
        promptPdf() {
            this.promptPdfActive = true;
        },
        beforeDownload () {
            // this.isLoading = true;
            if(this.photoLoaded >= this.photosCount) {
                this.updateContent().then(content => {
                    let pdfDocGenerator;
                    if (this.needPass){
                         pdfDocGenerator = pdfMake.createPdf({
                            content,
                            // userPassword: this.pdfPassword ? this.pdfPassword : 'test',
                            userPassword: this.pdfPassword,
                             pageSize: 'A4',
                            watermark: { text: this.watermark ?  this.user.login : "", color: 'red', opacity: 0.1, bold: true, italics: false },
                            fonts: {
                                normal: '/fonts/fontello.ttf',
                                bold: '/fonts/fontello.ttf',
                                italics: '/fonts/fontello.ttf',
                                bolditalics: '/fonts/fontello.ttf'
                            },
                            styles: {
                                header: {
                                    fontSize: 22,
                                    bold: true,
                                    marginBottom: 15,
                                    marginTop: 15
                                },
                                subheading: {
                                    fontSize: 16,
                                    bold: true,
                                    marginBottom: 10
                                }
                            }
                        });
                    } else {
                        pdfDocGenerator = pdfMake.createPdf({
                            content,
                            watermark: { text: this.watermark ?  this.user.login : "", color: 'red', opacity: 0.1, bold: true, italics: false },
                            styles: {
                                header: {
                                    fontSize: 22,
                                    bold: true,
                                    marginBottom: 15,
                                    marginTop: 15
                                },
                                subheading: {
                                    fontSize: 16,
                                    bold: true,
                                    marginBottom: 10
                                },
                                icon: { font: 'Fontello' }
                            }
                        });
                    }
                        pdfDocGenerator.getBlob( (blob) => {
                            this.isLoading = false
                            this.$toast.success( this.$t('search.pdf_saved') , {
                                position: "top-center",
                                timeout: 5000,
                                closeOnClick: true,
                                pauseOnFocusLoss: true,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.6,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: "button",
                                icon: true,
                                rtl: false
                            });
                            window.saveAs(blob, this.fileName + '.pdf')
                        });

                    
                })
            } else {
                this.beforeDownload();
            }
        },

         resizeImage(base64Str, maxWidth = 640, maxHeight = 640) {
                return new Promise((resolve) => {
                    let img = document.createElement('img');
                    img.src = base64Str
                    img.onload = () => {
                        let canvas = document.createElement('canvas')
                        const MAX_WIDTH = maxWidth
                        const MAX_HEIGHT = maxHeight
                        let width = img.width
                        let height = img.height

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width
                                width = MAX_WIDTH
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height
                                height = MAX_HEIGHT
                            }
                        }
                        canvas.width = Math.floor(width)
                        canvas.height = Math.floor(height)
                        let ctx = canvas.getContext('2d')
                        ctx.drawImage(img, 0, 0, width, height)
                        resolve(canvas.toDataURL())
                    }
                })
        },
        getBase64Image(img) {
            let canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            let dataURL = canvas.toDataURL("image/jpg");
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        },
        splitter(str, l){
            const strs = [];
            while(str.length > l){
                let pos = str.substring(0, l).lastIndexOf(' ');
                pos = pos <= 0 ? l : pos;
                strs.push(str.substring(0, pos));
                let i = str.indexOf(' ', pos)+1;
                if(i < pos || i > pos+l)
                    i = pos;
                str = str.substring(i);
            }
            strs.push(str);
            return strs;
        },
        makePause(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        setMountedPdfAction() {
            this.fileName = this.meta.key
            this.formProps.filename = this.fileName;
        }
    },
    watch: {
        promptPdfActive(val) {
            if(val) {               
                this.setMountedPdfAction();
            }
        }
    },
}