<template>
    <div>
        <header class="card-header">
            <div class="d-flex align-items-center">
                <figure class="flex-shrink-1 mb-0">
                    <p class="image is-24x24 mb-0"><img :src="source.icon">
                    </p>
                </figure>
                <div class="flex-grow-1 ms-3">
                    <div class="content">
                        <h2 class="mb-0" style="font-size: 1.75em;"> {{ source.source }} : ({{ resultCount }})</h2>
                    </div>
                </div>
                <figure class="media-right" style="margin-top: 0.6rem" @click="editMode()">
                    <i class="feather icon-edit cursor-pointer" :class="{'editButtonActive': editResults}"
                        :content="$t('result.edit_result')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                </figure>
            </div><!---->
            <div class="contact-controls-buttons" v-if="editResults">
                <b-button class="button" variant="danger" :disabled="!selectedResults.length"
                    @click="deleteRmModal()">{{ $t("result.delete_selected") }}</b-button>
                <div class="edit-results-container">
                    <b-button class="button" variant="primary" @click="selectAll()" style="margin-right: 8px">{{
                        $t("result.select_all") }}</b-button>
                    <b-button class="button" variant="secondary" @click="unselectAll()">{{ $t("result.remove_all")
                        }}</b-button>
                </div>
            </div>
        </header>
        <div class="card-body">
            <div class="searchResults list-group">
                <photo-result-item :sourceName="source.source" @select="addToSelected" @unselect="removeFromSelected"
                    :isChecked="selectedResults.includes(photoResultItem.data.id)" :editResults="editResults"
                    v-for="photoResultItem in source.search_results" :result="photoResultItem"></photo-result-item>
            </div>
            <div v-if="loadingPage">
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
                <b-skeleton width="60%"></b-skeleton>
                <b-skeleton width="80%"></b-skeleton>
                <b-skeleton width="50%"></b-skeleton>
                <b-skeleton width="75%"></b-skeleton>
                <b-skeleton width="65%"></b-skeleton>
            </div>
            <div class="row justify-content-center pt32" v-if="resultCount >= 30">
                <div class="col-12 col-sm-4">
                    <div class="d-grid">
                        <b-button @click="getPageResults"
                            variant="primary">{{ $t('result.show_more') }} <b-spinner v-if="loadingPage"></b-spinner> </b-button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="rm-modal" :title="$t('result.delete_search_result')" hide-footer centered>
            <div class="d-grid">
                <b-button class="mt-3" variant="danger" block @click="removeSelected()">{{
                    $t('modal.actionDelete') }}</b-button>
                <b-button class="mt-2" variant="secondary" block @click="hideRmModal">{{ $t('modal.cancel')
                    }}</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import env from "@/configs/env.json";
import { isUndefined } from "underscore";
import { mapGetters } from "vuex";
export default {
    props: ['source'],
    data() {
        return {
            prefix: env.prefix,
            editResults: false,
            selectedResults: [],
            loadingPage: false
        }
    },
    components: {
        photoResultItem: () => import('./photoResultItem.vue')
    },
    computed: {
        ...mapGetters('contacts', ['meta']),
        resultCount() {
            return this.source.search_results ? this.source.search_results.length: 0;
        },
    },
    methods: {
        editMode() {
            this.editResults = !this.editResults;
        },
        selectAll() {
            this.selectedResults = this.source.search_results.map(result => {
                return result.data.id;
            });
        },
        unselectAll() {
            this.selectedResults = [];
        },
        addToSelected(resultId) {
            this.selectedResults.push(resultId);
        },
        removeFromSelected(resultId) {
            this.selectedResults = this.selectedResults.filter(id => id !== resultId);
        },
        removeSelected() {
            this.$store.dispatch('contacts/removePhotoItem', 
                { 
                    source: this.source.source,
                    resultIds: this.selectedResults
                }).then(() => {
                    this.selectedResults = [];
                    this.hideRmModal();
                });
        },
        deleteRmModal() {
            this.$refs['rm-modal'].show();
        },
        hideRmModal() {
            this.$refs['rm-modal'].hide();
        },
        getPageResults() {
            const offset = this.source.offset || this.source.search_results.length;
            const payload = {
                key: this.meta.key,
                search_request_id: this.source.request_id,
                source_id: this.source.source_id,
                limit: 30,
                offset: offset              
            }
            this.loadingPage = true;
            this.$store.dispatch('contacts/paginatePhotoSource', payload).then(() => {
                this.loadingPage = false;
            });
        }
    }
}
</script>
<style>
    .cursor-pointer {
        cursor: pointer;
    }
    .edit-results-container{
        display: flex;
        justify-content: start;
        width: 100%;
    }
    .pt32 {
        padding-top: 32px;
    }
</style>